<template>
  <div
    @click="emits('close')"
    :class="[$style.backdrop, active && $style.active]"
  />
  <div :class="[$style.mmenu, active && $style.active]">
    <div :class="$style.head">
      <client-only>
        <div
          :class="$style.lang"
          v-click-outside="hideLangs"
        >
          <div
            :class="$style.langButton"
            @click.prevent="toggleLangs"
          >
            <UiButton
              type="icon"
              color="gray"
              :class="$style.langSwitcher"
            >
              <template #label>
                <UiIconsPlanet />
              </template>
            </UiButton>
            {{ currentLocalName }}
          </div>

          <LanguageSelector
            :visible="showLangsSelect"
            @close="hideAll"
          />
        </div>
      </client-only>

      <div
        @click="emits('close')"
        :class="$style.close"
      >
        <UiIconsClose />
      </div>
    </div>

    <HeaderNav :class="$style.nav" @close="emits('close')"/>

    <div :class="$style.footer">

      <UiButton
        type="smallest"
        color="gray"
        @click="emits('close')"
        :to="localePath(user ? '/account' : '/auth/signup')"
      >
        <template
          v-if="user"
          #label>
          {{ t('header.account') }}
        </template>

        <template
          v-else
          #label>
          {{ t('header.signup') }}
        </template>

        <template #preppendIcon>
          <UiIconsSign />
        </template>
      </UiButton>

      <nuxt-link
        v-if="!user"
        :to="localePath('/auth/signin')"
        @click="emits('close')"
        :class="$style.actionAuth"
      >
        {{ t('header.logIn') }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

interface MMenuParams {
  active: boolean,
}

interface MmenuEmits {
  (e: 'close'): void,
}

const { t } = useI18n();

defineProps<MMenuParams>();
const emits = defineEmits<MmenuEmits>();
const { locale: currentLocal, locales } = useI18n();
const currentLocalName = computed<string>(() => locales.value.find((local) => local.code === currentLocal.value)?.name || '');
const showLangsSelect = ref<boolean>(false);
const hideLangs = () => showLangsSelect.value = false;
const hideAll = () => {
  hideLangs();
  emits('close');
}
const toggleLangs = () => showLangsSelect.value = !showLangsSelect.value;
</script>

<style lang="scss" module>
  .actionAuth {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #71787F;
    color: #71787F;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .lang {
    z-index: 10;
    position: relative;

    &Button {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    margin-top: auto;

    > a {
      height: 38px;
      text-decoration: none;
      border-radius: 19px;

      > span {
        width: 100%;
        padding-right: 0 !important;
      }
    }
  }

  .nav {
    flex: 1;
    overflow-y: auto;
  }

  .backdrop {
    z-index: 8;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(25, 26, 55, 0.50);
    visibility: hidden;
    opacity: 0;
    transition: 150ms ease;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .close {
    margin-left: auto;
    color: #121e29;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: auto;
    }
  }

  .head {
    display: flex;
    align-items: center;
    color: #121e29;
    gap: 8px;
  }

  .langSwitcher {
    color: #fff;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mmenu {
    z-index: 9;
    position: fixed;
    top: 0;
    right: 0;
    width: 352px;
    max-width: calc(100% - 22px);
    height: var(--visual-viewport-height);
    border-radius: 24px 0 0 24px;
    background-color: #fff;
    padding: 22px 16px 30px 22px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    transition: 200ms ease;
    transform: translateX(100%);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateX(0);
    }
  }
</style>