<template>
  <header :class="[$style.header, 'container']">
    <nuxt-link :to="localePath('/')" :class="$style.logo">
      <IconsLogo :class="$style.logoIcon"/>
    </nuxt-link>

    <div :class="$style.nav">
      <HeaderNav />
    </div>

    <div :class="$style.tools">
      <client-only>
        <div
          :class="$style.lang"
          v-click-outside="hideLangs"
        >
          <UiButton
            type="icon"
            color="gray"
            @click.prevent="toggleLangs"
          >
            <template #label>
              {{ currentLocal }}
            </template>
          </UiButton>

          <LanguageSelector
            :visible="showLangsSelect"
            @close="hideLangs"
          />
        </div>
      </client-only>

      <UiButton
        type="smallest"
        color="gray"
        @click.prevent="relocate"
      >
        <template
          v-if="user"
          #label>
          {{ t('header.account') }}
        </template>

        <template
          v-else
          #label>
          {{ t('header.signup') }}
        </template>

        <template #preppendIcon>
          <UiIconsSign />
        </template>
      </UiButton>
    </div>

    <div :class="$style.burger" @click="showMmenu">
      <UiIconsBurger />
    </div>
  </header>
  <HeaderMMenu :active="isMmenu" @close="hideMmenu"/>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const isMmenu = ref<boolean>(false);

const { t } = useI18n();
const router = useRouter();
const localePath = useLocalePath();

const showMmenu = () => isMmenu.value = true;
const hideMmenu = () => isMmenu.value = false;

const { locale: currentLocal } = useI18n();
const showLangsSelect = ref<boolean>(false);
const hideLangs = () => showLangsSelect.value = false;
const toggleLangs = () => showLangsSelect.value = !showLangsSelect.value;

const relocate = () => {
  if (user.value) {
    router.push(localePath('/account'));
  } else {
    fbq('track', 'InitiateCheckoutl');
    router.push(localePath('/auth/signup'));
  }
}
</script>

<style lang="scss" module>
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      padding-top: 32px;
      padding-bottom: 42px;
    }

    @media (max-width: 1023px) {
      padding-top: 26px;
      padding-bottom: 32px;
    }

    @media (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    > * {
      flex: 1;
    }
  }

  .lang {
    position: relative;

    button {
      text-transform: uppercase;
    }
  }

  .logo {
    display: flex;
    align-items: center;

    &Icon {
      width: 106px;
      height: auto;
    }

    @media (max-width: 767px) {
      margin-right: auto;
      flex: unset
    }
  }

  .nav {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .tools {
    display: flex;
    justify-content: flex-end;
    gap: 2px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .burger {
    @media (min-width: 1024px) {
      display: none;
    }

    @media (max-width: 1023px) {
      width: 22px;
      height: auto;
      color: #121E29;
      margin-left: auto;
      flex: unset;

      svg {
        width: 22px;
        height: auto;
      }
    }
  }
</style>