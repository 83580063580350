<template>
  <nav :class="$style.nav">
    <ul :class="$style.navList">
      <li><nuxt-link @click="emits('close')" :to="localePath('/prices')">{{ t('navigation.pricing') }}</nuxt-link></li>
      <li><nuxt-link @click="emits('close')" :to="localePath('/downloads')">{{ t('navigation.download') }}</nuxt-link></li>
      <li><nuxt-link @click="emits('close')" :to="localePath('/referal')">{{ t('navigation.referral') }}</nuxt-link></li>
      <li><nuxt-link @click="emits('close')" :to="localePath('/partners')">{{ t('navigation.partners') }}</nuxt-link></li>
      <li>
        <a href="#">
          {{ t('navigation.info') }}
          <IconsArrowDown :class="$style.arrow"/>
        </a>
        <ul :class="$style.navDropdown">
          <li><nuxt-link @click="emits('close')" :to="localePath('/blog')">{{ t('navigation.blog') }}</nuxt-link></li>
          <li><nuxt-link @click="emits('close')" :to="localePath('/faq')">{{ t('navigation.faq') }}</nuxt-link></li>
          <li><a href="https://t.me/aqum_support" target="_blank">{{ t('navigation.support') }}</a></li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
interface MmenuEmits {
  (e: 'close'): void,
}

const { t } = useI18n();

const emits = defineEmits<MmenuEmits>();
</script>

<style lang="scss" module>
  .nav {

    @media (min-width: 1024px) {
      background: #FBFCFF;
      border-radius: 22.5px;
      padding: 12px 34px;
      height: 45px;

      &List {
        display: flex;
        align-items: center;
        gap: 46px;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
        }
      }
    }

    @media (max-width: 1023px) {
      font-size: 16px;
      margin-bottom: auto;

      &List {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }

    a {
      display: flex;
      align-items: center;
      gap: 11px;
      color: #121E29;
      text-decoration: none;
      transition: 100ms ease;

      &:hover {
        color: #293EFF;
      }

      &.router-link-active, &.router-link-exact-active {
        color: #293EFF!important;
      }
    }

    :global(.router-link-active),
    :global(.router-link-exact-active) {
      color: #293EFF !important;
    }

    li {

      &:hover {

        .navDropdown {
          opacity: 1;
          visibility: visible;
          transition: 200ms ease;
        }
      }
    }
  }

  .navDropdown {
    z-index: 10;
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    transition-delay: 100ms;

    @media (min-width: 1024px) {
      top: 100%;
      position: absolute;
      width: 176px;
      gap: 12px;
      padding: 12px 12px 18px 12px;
      background: #FBFCFF;
      border-radius: 10px;
      transform: translate(-12px, 16px);
    }

    @media (max-width: 1023px) {
      padding: 0;
      gap: 24px;
      padding-top: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 16px;
    }
  }

  .arrow {
    flex-shrink: 0;
    width: 8px;
    height: 4px;
    transition: 100ms ease;
  }
</style>