<template>
  <div :class="[$style.langSelector, visible && $style.visible]">
    <nuxt-link
      v-for="locale in locales"
      :key="locale.code"
      :class="[$style.item, currentLocal === locale.code && $style.active]"
      :to="switchLocalPath(locale.code)"
      @click="emits('close')"
    >
      {{ locale.name }}
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
import type { LanguageSelectorEmits, LanguageSelectorParams } from '.';

defineProps<LanguageSelectorParams>();
const emits = defineEmits<LanguageSelectorEmits>();
const { locale: currentLocal, locales } = useI18n();
const switchLocalPath = useSwitchLocalePath();
</script>

<style lang="scss" module>
  .langSelector {
    z-index: 10;
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    transition-delay: 100ms;
    top: 100%;
    position: absolute;
    width: 158px;
    gap: 12px;
    padding: 12px 12px 18px 12px;
    background: #39415B;
    border-radius: 10px;
    transform: translate(0px, 16px);

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 16px;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    a {
      display: flex;
      align-items: center;
      gap: 11px;
      color: #fff;
      text-decoration: none;
      transition: 100ms ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
</style>