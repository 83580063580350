<template>
  <nav :class="$style.nav">
    <div>
      <div :class="$style.label">{{ t('footerNav.product') }}</div>
      <ul>
        <li><nuxt-link :to="localePath('/prices')">{{ t('footerNav.pricing') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/downloads')">{{ t('footerNav.download') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/blog')">{{ t('footerNav.blog') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/referal')">{{ t('footerNav.referral') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/partners')">{{ t('footerNav.partners') }}</nuxt-link></li>
      </ul>
    </div>
    <div>
      <div :class="$style.label">{{ t('footerNav.reference') }}</div>
      <ul>
        <li><a href="https://t.me/aqum_support" target="_blank">{{ t('footerNav.support') }}</a></li>
        <li><nuxt-link :to="localePath('/faq')">{{ t('footerNav.api') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/faq')">{{ t('footerNav.help') }}</nuxt-link></li>
      </ul>
    </div>
    <div>
      <div :class="$style.label">{{ t('footerNav.download') }}</div>
      <ul>
        <li><nuxt-link :to="localePath('/downloads')">Windows</nuxt-link></li>
        <li><nuxt-link :to="localePath('/downloads')">MacOS</nuxt-link></li>
        <li><nuxt-link :to="localePath('/downloads')">MacOS M series</nuxt-link></li>
      </ul>
    </div>
    <div>
      <div :class="$style.label">{{ t('footerNav.legal') }}</div>
      <ul>
        <li><nuxt-link :to="localePath('/documents/terms')">{{ t('footerNav.terms') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/documents/ua')">{{ t('footerNav.agree') }}</nuxt-link></li>
        <li><nuxt-link :to="localePath('/documents/privacy')">{{ t('footerNav.privacy') }}</nuxt-link></li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style lang="scss" module>
  .nav {
    display: flex;
    justify-content: space-between;
    gap: 64px;

    @media (max-width: 1023px) {
      gap: 38px;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    > div {
      flex: 40%;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 7px;
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  .label {
    margin-bottom: 14px;
    color: #121E29;
    font-family: 'Gilroy';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }
</style>